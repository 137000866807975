body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  color: #fff;
  background: url("MassBlur.d2432c5e.jpg") center / cover no-repeat fixed;
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

button {
  color: #fff;
  width: 12vh;
  height: 5vh;
  background-color: #fff0;
  border: none;
  border-radius: .3rem;
  margin: 0 .2rem;
  font-size: 1rem;
  transition: background-color .4s;
}

button:hover {
  background-color: #ffffff5e;
}

.header-container {
  height: 12vh;
  grid-template-columns: 1fr 1fr;
  place-items: stretch stretch;
  display: grid;
}

.header-logo {
  grid-column: 1;
  margin: auto 0;
}

.logo {
  height: 2.3rem;
  width: auto;
  margin-left: 1rem;
}

.header-text {
  font-size: 3rem;
}

.nav {
  grid-column: 2;
  grid-template-rows: 1fr 1fr;
  justify-content: end;
  margin: auto 1rem auto 0;
  display: flex;
}

.svg-icon {
  width: auto;
  height: 1.8rem;
  fill: currentColor;
  display: none;
}

.navbuttons {
  display: flex;
}

.image {
  grid-row: 3;
}

.image > img {
  height: 60vh;
  width: 100%;
  object-fit: cover;
  object-position: 50% 0;
  margin: 0 auto;
}

.special-container {
  grid-template-columns: 1fr minmax(0, 400px) 1fr;
  place-items: stretch stretch;
  display: grid;
}

.special1 {
  text-align: center;
  border-bottom: .05rem solid #ffffff73;
  grid-column: 1;
  margin: auto 0 auto 1rem;
  padding-right: 6rem;
}

.divider {
  text-align: center;
  grid-column: 2;
  margin: 2rem 0;
  font-size: 4rem;
}

.special2 {
  text-align: center;
  border-bottom: .05rem solid #ffffff73;
  grid-column: 3;
  margin: auto 1rem auto 0;
  padding-left: 6rem;
}

.card-container {
  grid-template-columns: auto auto;
  place-items: stretch stretch;
  display: grid;
}

#card-one {
  grid-column: 1;
  margin-right: 2vh;
}

#card-two {
  grid-column: 2;
  margin-left: 2vh;
}

.card {
  min-height: auto;
  min-width: 20vh;
  max-width: 60vh;
  background-color: #0000005b;
  border-radius: 1.3rem;
  margin: 2vh auto;
  padding: 3vh 5vh;
}

.card-body {
  justify-content: center;
  display: flex;
}

.card-image {
  margin: auto 0;
}

.link-logos {
  height: auto;
  width: 5rem;
}

.card-header {
  line-height: 20%;
}

.card-text {
  margin-left: 1.5rem;
}

.aboutMeContainer {
  grid-template-columns: 1fr 2fr 1fr;
  display: grid;
}

.aboutMe {
  grid-column: 2;
  font-size: 1.3rem;
  line-height: 2rem;
}

.text-container {
  grid-template-columns: 1fr 1fr;
  margin-left: 2rem;
  font-size: large;
  display: grid;
}

.post {
  background-color: #00000046;
  border-radius: .8rem;
  grid-column: 1;
  padding: .5rem;
}

.post-image {
  grid-column: 2;
  margin: auto;
}

.post-image > img {
  height: auto;
  width: 700px;
  border-radius: .8rem;
  margin: auto 1rem;
}

li {
  line-height: 2rem;
}

form {
  grid-template-columns: auto;
  justify-content: center;
  display: grid;
}

.contactMeButton {
  border: none;
  border-radius: .3rem;
  padding: .25rem;
  box-shadow: .1rem .1rem #0000005a;
}

#smallTextarea {
  display: none;
}

.gamesContainer {
  grid-template-columns: 1fr 5fr 1fr;
  display: grid;
}

.gamesRow {
  grid-column: 2;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .header-container {
    grid-template-columns: 5fr 1fr;
  }

  .svg-icon {
    flex: end;
    display: flex;
  }

  .navbuttons {
    display: none;
  }

  .special-container {
    grid-template-columns: 1fr minmax(0, 200px) 1fr;
  }

  .special1 {
    padding-right: 4rem;
  }

  .divider {
    font-size: 2rem;
  }

  .special2 {
    padding-left: 4rem;
  }

  .card-header {
    font-size: medium;
  }

  .card-message {
    font-size: small;
  }

  .card-container {
    grid-template-columns: auto;
    margin: 0 2vh;
  }

  #card-one {
    margin: 2vh auto;
  }

  #card-two {
    grid-column: 1;
    margin: 2vh auto;
  }

  .buttonContainer {
    justify-content: end;
  }

  .buttonBox {
    flex-direction: column;
    display: flex;
  }

  .buttonBox#mobile > button {
    margin-bottom: 1rem;
  }

  .aboutMeContainer {
    grid-template-columns: 5% 1fr 5%;
  }

  h1 {
    line-height: 2.5rem;
  }

  .text-container {
    grid-template-columns: 1fr 3fr 1fr;
  }

  .post {
    grid-column: 2;
  }

  .post-image {
    grid-column: 2;
    margin-top: 1rem;
  }

  .form {
    margin: 0 auto;
  }

  #largeTextarea {
    display: none;
  }

  #smallTextarea {
    display: block;
  }
}

/*# sourceMappingURL=index.784b4caf.css.map */
