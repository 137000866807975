body {
    background: url(images/MassBlur.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    color: white;
}

a {
    color: white;
    text-decoration: none;
}


/*********Nav button handler********/
button {
    font-size: 1rem;
    color: white;
    margin: 0 .2rem;
    width: 12vh;
    height: 5vh;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    border-radius: .3rem;
    -webkit-transition: background-color .4s;
    transition: background-color .4s;
}

button:hover {
    background-color: rgba(255, 255, 255, 0.37);
}


/*********Header/Nav handler********/
.header-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
    justify-items: stretch;
    height: 12vh;
}

.header-logo {
    grid-column: 1;
    margin: auto 0;
}

.logo {
    height: 2.3rem;
    width: auto;
    margin-left: 1rem;
}

.header-text {
    font-size: 3rem;
}

.nav {
    grid-template-rows: 1fr 1fr;
    display: flex;
    grid-column: 2;
    justify-content: end;
    margin: auto 1rem auto 0;
}

.svg-icon {
    display: none;
    width: auto;
    height: 1.8rem;
    fill: currentColor;
}

.navbuttons {
    display: flex;
}




/*********Image handler********/
.image {
    grid-row: 3;
}

.image>img {
    height: 60vh;
    width: 100%;
    object-fit: cover;
    object-position: 50% 0;
    margin: 0 auto;
}




/*********Special Divider handler********/
.special-container {
    display: grid;
    grid-template-columns: 1fr minmax(0, 400px) 1fr;
    align-items: stretch;
    justify-items: stretch;
}

.special1 {
    grid-column: 1;
    border-bottom: solid .05rem rgba(255, 255, 255, 0.45);
    text-align: center;
    margin: auto 0 auto 1rem;
    padding-right: 6rem;
}

.divider {
    grid-column: 2;
    margin: 2rem 0;
    font-size: 4rem;
    text-align: center;
}

.special2 {
    grid-column: 3;
    border-bottom: solid .05rem rgba(255, 255, 255, 0.45);
    text-align: center;
    margin: auto 1rem auto 0;
    padding-left: 6rem;
}




/********Card container handler*********/
.card-container {
    display: grid;
    grid-template-columns: auto auto;
    align-items: stretch;
    justify-items: stretch;
}

#card-one {
    grid-column: 1;
    margin-right: 2vh;
}

#card-two {
    grid-column: 2;
    margin-left: 2vh;
}

.card {
    background-color: rgba(0, 0, 0, 0.358);
    border-radius: 1.3rem;
    min-height: auto;
    min-width: 20vh;
    max-width: 60vh;
    margin: 2vh auto;
    padding: 3vh 5vh;
}

.card-body {
    display: flex;
    justify-content: center;
}

.card-image {
    margin: auto 0;
}

.link-logos {
    height: auto;
    width: 5rem;
}

.card-header {
    line-height: 20%;
}

.card-text {
    margin-left: 1.5rem;
}




.aboutMeContainer {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

.aboutMe {
    grid-column: 2;
    font-size: 1.3rem;
    line-height: 2rem;
}






.text-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: large;
    margin-left: 2rem;
}

.post {
    grid-column: 1;
    background-color: rgba(0, 0, 0, 0.276);
    padding: .5rem;
    border-radius: .8rem;
}

.post-image {
    grid-column: 2;
    margin: auto;
}

.post-image>img {
    height: auto;
    width: 700px;
    border-radius: .8rem;
    margin: auto 1rem;
}

li {
    line-height: 2rem
}






form {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
}

.contactMeButton {
    padding: .25rem;
    border-radius: .3rem;
    border: none;
    box-shadow: .1rem .1rem rgba(0, 0, 0, 0.352);
}

#smallTextarea {
    display: none;
}



.gamesContainer {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
}

.gamesRow {
    grid-column: 2;
    margin: 0 auto;
}


@media screen and (max-width: 1200px) {
    .header-container {
        grid-template-columns: 5fr 1fr;
    }

    .svg-icon {
        display: flex;
        flex: end;
    }

    .navbuttons {
        display: none;
    }


    /****** SPECIAL BOX *******/
    .special-container {
        grid-template-columns: 1fr minmax(0, 200px) 1fr;
    }

    .special1 {
        padding-right: 4rem;
    }

    .divider {
        font-size: 2rem;
    }

    .special2 {
        padding-left: 4rem;
    }




    .card-header {
        font-size: medium;
    }

    .card-message {
        font-size: small;
    }

    .card-container {
        grid-template-columns: auto;
        margin: 0 2vh;
    }

    #card-one {
        margin: 2vh auto;
    }

    #card-two {
        margin: 2vh auto;
        grid-column: 1;
    }



    .buttonContainer {
        justify-content: end;
    }

    .buttonBox {
        display: flex;
        flex-direction: column;
    }

    .buttonBox#mobile>button {
        margin-bottom: 1rem;
    }


    .aboutMeContainer {
        grid-template-columns: 5% 1fr 5%;
    }

    h1 {
        line-height: 2.5rem;
    }


    .text-container {
        grid-template-columns: 1fr 3fr 1fr;
    }

    .post {
        grid-column: 2;
    }

    .post-image {
        margin-top: 1rem;
        grid-column: 2;
    }

    .form {
        margin: 0 auto;
    }

    #largeTextarea {
        display: none;
    }

    #smallTextarea {
        display: block;
    }
}